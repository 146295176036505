/* .MuiPaper-root{
  
} */

.makeStyles-paper-90{
  color: #000000 !important;
}

/* table{
  width: 100%;
}
tbody{
  height: 30vh;
  overflow: auto;
} */
