*{
  box-sizing: border-box;
}

body{
  background-color: #001B7C;
}

h1 {
  margin: 0px;
  color: #FFFFFF;
}

/* .MuiTabs-root{
  background-color: #35DEBB;
} */

.MuiTabs-fixed {
  margin: auto;
}
