.MuiToolbaar-root .MuiIconButton-edgeStart {
    margin-left: 0px;
}


/* .MuiPaper-root .MuiTabs-root {
  background-color: white;
} */

.MuiTabs-scroller .MuiButtonBase-root {
    min-height: 48px;
    font-size: 1rem;
    padding: 8px 18px;
    margin: 1px 0;
}

.MuiTabs-flexContainerVertical .MuiButtonBase-root .MuiTab-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: flex-start;
    text-transform: capitalize;
    font-weight: normal;
}

.MuiTabs-scroller .MuiButtonBase-root .MuiTab-wrapper svg {
    margin-bottom: 0;
    /* margin-left: 8px; */
    margin-right: 40px;
    text-align: left;
}

.MuiTypography-root .MuiBox-root {
    padding-top: 16px;
    padding-bottom: 8px;
}

.MuiTableBody-root th {
    padding-left: 5px;
}

.MuiFormControl-root .MuiInputBase-root {
    margin: 0;
}

#outlined-adornment-amount {
    padding: 10px 14px 10px 0px;
}

.MuiTableRow-root .iconCell {
    padding: 0px;
}

.MuiInputBase-root #date-picker-dialog {
    padding: 12px 14px;
}


/* .MuiDialog-root-logout .MuiDialog-container .MuiPaper-root{
  max-height: 200px;
}   */

.MuiDialog-root-logout .MuiDialog-container .MuiPaper-root .MuiDialogActions-root {
    justify-content: space-around;
}

#alert-dialog-title {
    margin-top: 35px;
}

.align-content-center {
    text-align: center;
}

.flex-direction-column {
    flex-direction: column;
}

#menu .MuiPaper-root {
    height: 10%
}

.MuiFormLabel-asterisk {
    display: none;
}

#standard-select-department,
.MuiPopover-root .MuiPopover-paper .MuiList-root .MuiListItem-root {
    text-transform: capitalize;
}

#switchComponent .MuiFormGroup-root .MuiFormControlLabel-root {
    margin: 0;
    margin: auto;
}

#submitButton {
    text-align: center;
    margin: 16px 0;
}

.hideBackground {
    background-color: transparent;
}

.iconCell:hover {
    cursor: pointer;
}

body>.MuiDrawer-root {
    position: absolute!important;
}

.MuiCircularProgress-root {
    height: 100%!important;
}

div[class^='PrivateTabScroll'] {
    display: none;
}


/* div[class*='MuiGrid-spacing-xs']{
  width: 100%;
  margin: 0;
} */


/* //Hide scrollbar for Chrome, Safari and Opera
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

//Hide scrollbar for IE and Edge
.hideScrollbar {
    -ms-overflow-style: none;
} */
.hideScrollbar{
    overflow-y: scroll!important;
}

.hideScrollbar::-webkit-scrollbar {
    background-color: #fff;
    width: 5px
}

.hideScrollbar::-webkit-scrollbar-track {
    background-color: #fff
}

.hideScrollbar::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    /* border: 4px solid #fff */
}